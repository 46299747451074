<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        <router-link to="/tpr-config">
          <i class="el-icon-back mr10" />
        </router-link>
        TPR хязгаарлалтын түүх
      </h3>
    </div>
    <el-row :gutter="0">
      <el-col :span="24" :offset="0">
        <div class="panel">
          <div align="right">
            <el-input
              style="width: 200px"
              prefix-icon="el-icon-search"
              v-model="search"
              size="mini"
              placeholder="Хайх үйлчилгээний нэр"
              @input="inputText"
            />
            <el-date-picker
              class="ml10"
              size="mini"
              value-format="yyyy-MM-dd"
              v-model="filterDate"
              type="datetimerange"
              start-placeholder="Эхлэх өдөр"
              end-placeholder="Дуусах өдөр"
              @change="setDate"
            >
            </el-date-picker>
          </div>
          <el-table :data="tprHistoryData" size="mini">
            <el-table-column label="Hexagon">
              <template slot-scope="scope">
                <span> >{{ scope.row.config.hexa_name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Утас" prop="user_phone"></el-table-column>
            <el-table-column label="TPR" prop="tpr"></el-table-column>
            <el-table-column label="TPR хязгаарлалт">
              <template slot-scope="scope">
                <span>{{ scope.row.config.tpr_limit }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="Блоклосон өдөр"
              prop="blocked_at"
            ></el-table-column>
            <el-table-column label="Харилцагч">
              <template slot-scope="scope">
                <span>{{ scope.row.outlet.outlet_name_mon }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Эхлэх өдөр">
              <template slot-scope="scope">
                <span>{{ scope.row.config.start_date }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Дуусах өдөр">
              <template slot-scope="scope">
                <span>{{ scope.row.config.end_date }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Эхлэх цаг">
              <template slot-scope="scope">
                <span>{{ scope.row.config.start_time }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Дуусах цаг">
              <template slot-scope="scope">
                <span>{{ scope.row.config.end_time }}</span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="mt10 text-right"
            background
            @size-change="sizeChangeHandler"
            layout="total, sizes, prev, pager, next"
            :total="pageCount"
            :page-size="size"
            :page-sizes="[5, 10, 20, 50, 100]"
            @current-change="setPage"
          >
          </el-pagination>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import service from "@/helpers/services.js";
export default {
  name: "tprConfigHistory",

  mounted() {
    this.getTprHistory();
  },

  data() {
    return {
      filterDate: [],
      tprHistoryData: [],
      pageCount: 0,
      size: 20,
      from: 0,
      search: ""
    };
  },

  methods: {
    setDate() {
      this.getTprHistory();
    },

    inputText(text) {
      this.search = text;
      this.getTprHistory();
    },

    async getTprHistory() {
      this.openFullScreenLoader(true);
      service
        .getTprHistory(
          this.size,
          this.from,
          this.search,
          this.filterDate === null
            ? ""
            : this.filterDate[0] === undefined
            ? ""
            : this.filterDate[0],
          this.filterDate === null
            ? ""
            : this.filterDate[1] === undefined
            ? ""
            : this.filterDate[1]
        )
        .then(response => {
          if (response.data.status === "success") {
            this.tprHistoryData = response.data.data;
            this.pageCount = response.data.total;
            this.openFullScreenLoader(false);
          } else {
            this.openFullScreenLoader(false);
          }
        });
    },

    setPage(page) {
      this.from = (page - 1) * this.size;
      this.getTprHistory();
    },

    sizeChangeHandler(size) {
      this.size = size;
      this.getTprHistory();
    },

    openFullScreenLoader(status) {
      if (status) {
        this.loading = this.$loading({
          lock: true,
          text: "татаж авч байна...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      } else {
        this.loading.close();
      }
    }
  }
};
</script>

<style></style>
